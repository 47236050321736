.disclosure {
  position: relative;
}

.disclosure__button {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 4rem;
  padding: 0 1.5rem 0 1.5rem;
  font-size: 1.3rem;
  background-color: transparent;
}

.disclosure__list {
  border: 1px solid rgba(var(--color-foreground), 0.2);
  font-size: 1.4rem;
  margin-top: -0.5rem;
  min-height: 8.2rem;
  max-height: 19rem;
  max-width: 22rem;
  min-width: 12rem;
  width: max-content;
  overflow-y: auto;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  position: absolute;
  bottom: 100%;
  transform: translateY(-1rem);
  z-index: 2;
  background-color: rgb(var(--color-background));
}

.disclosure__item {
  position: relative;
}

.disclosure__link {
  display: block;
  padding: 0.5rem 2.2rem;
  text-decoration: none;
  line-height: calc(1 + 0.8 / var(--font-body-scale));
}
